/* You can add global styles to this file, and also import other style files */

@import "../node_modules/snl-complib/themes/theme-colors.scss";
@import "../node_modules/snl-complib/themes/typography.scss";
@import "../node_modules/snl-complib/themes/spacing.scss";
@import "../node_modules/snl-complib/themes/common.scss";
@import "../node_modules/snl-complib/themes/snl-complib.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");

@import "./assets/themes/common.scss";
@import "./assets/themes/snl-complib.scss";
@import "./assets/themes/spacing.scss";
@import "./assets/themes/theme-colors.scss";
@import "./assets/themes/typography.scss";
@import "./assets/themes/custome-style.scss";


.highcharts-axis-labels .lastLabel {
    margin-left: -30px;
  }
  .yAxis {
    text-align: right;
    font-family: var(--font-family-roboto-regular);
    z-index: 0;
  }
  .lowercase {
    text-transform: lowercase;
  }
  .noRecordsFound{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px !important;
  }
  .enabled{
    pointer-events: auto;
    cursor: pointer;
  }
  .toast-container .ngx-toastr {width: 375px !important;}
 
  .inp__input::placeholder {opacity: 1 !important;}
  snl-time-picker-select {width: 100% !important;}
  .container__picker .container__picker__time .section-title {
    font-family: var(--font-family-roboto-regular);
    width: 100%;
  }
  .wrapper{
    .modal{
      form
      {
        margin-top: 5px;
      }
    }
  }
  .dropdown-col {
    ng-select {
      .ng-select-container{
        overflow: initial;
        min-height: 100% !important;
        background-color: transparent;
        height: 100%;
        min-width: 100px;
      }
    }
  }
  .inp__suffix--wrapper {
    margin-right: var(--spacing-8) !important;
    .inp__suffix--clear-icon{
      margin-right: var(--spacing-0) !important;
    }
  }
  form {
    .datepicker{
      .inp__container--input{pointer-events: none;}
      .inp__suffix--clear-icon{display: none;}
    }
    :focus-visible {
      border: none;
      outline: none;
    }
  }
  .container__picker{
    .container__picker-label{
      transform: translateY(-4px);
    }
  }
  .primary-icon{
    i{color: #fff !important;}
  }

.single-selector{
    .ng-select-single{
        .ng-select-container{
           .ng-value-container{
                min-height: 48px;
                min-width: 145px;
                .ng-value{display: none;}
            }
        }
        
        
    }
    &._TextFyear{
      .ng-select-single{
        .ng-select-container{
          .ng-value-container{
             min-width: 155px;
          }
       }
      }
    }
    &.active{
        .ng-select-single{
            .ng-select-container{
                .ng-value-container{
                    min-width: 100% !important;
                }
            }
        }
    }
}



.dropdown-col._TextFltr.single-selector  {
    .ng-select-single{
        .ng-select-container {
            .ng-value-container::before{
                color: var(--font-color-secondary);
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.2px; 
                top: 12px;
            }
        }
        &.ng-select-opened.placeholder-hide{
            .ng-select-container {
                .ng-value-container::before{
                  color:var(--primary-default);
                  top: -8px;
                  transition: 0.2s;
                  font-size: 12px;
                  line-height: 16px;
                  letter-spacing: 0.2px; 
                }
            }
        }
    }
    &.active{
        .ng-select-container {
            .ng-value-container::before{
                top: -8px;
                transition: 0.2s;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.2px; 
            }
        }
    }
}

.inp__container--label-float-number:not(.inp__container--label-error, .inp__container--label-disabled){
  color: var(--font-color-primary);
}
snl-toast {
  margin-bottom: 40px;
  margin-right: 40px;
  .section-title {
    font-family: var(--font-family-roboto-regular);
  }
}
.disabled {
  pointer-events: none;
}
.table-hover{
  snl-table{
    snl-row:hover snl-td{
      background-color: var(--neutral-11);cursor: pointer;
    }
    snl-row:hover {
        box-shadow: 0px 10px 15px 0px rgba(15, 26, 42, 0.1);
        background-color: transparent !important;
    }
    snl-row.cursor-pointer {
      background-color: transparent !important;
    }
    snl-row{&.table-primery-heading:hover{box-shadow: none;}}
  }
}

.fintooltipContent{
  display: flex;flex-direction: column;justify-content: center;align-items: flex-start;
  .finance-tooltip {
    flex-direction: column;
    align-items: flex-start;
    .comments {
      margin-top: var(--spacing-8);
    }
    .data-cont {
      padding-bottom: 16px;
      justify-content: space-between;
    }
    .data-cont:nth-child(3n) {
      padding-bottom: 0px;
    }
    .divider {
      border-bottom: var(--spacing-1) solid var(--neutral-9);
      margin: var(--spacing-16) var(--spacing-0) var(--spacing-16) var(--spacing-0);
    }
    .col-1 {
      min-width: 142px;
      text-align: left;
    }
    .col-2 {
        min-width: 142px;
        justify-content: end;
    }
    .total{
      align-items: center;
    }
  }
}
