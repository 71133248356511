
/* ------------------------> Container Card Style <------------------------ */
.CommonContainerCard{
    background: var(--neutral-12); 
    margin: var(--spacing-24) var(--spacing-0) var(--spacing-40);
    padding: var(--spacing-24) var(--spacing-16);
    &.mt_0{
      margin-top: 0;
    }
    &.card-mrgn-b-32{
      margin-bottom: var(--spacing-32);
    }
    .action-panel-main{
      snl-action-panel{
        box-shadow: none;
        padding: var(--spacing-0);
      }
    }
  }
  .breadcrumb-main{ 
    padding: var(--spacing-24) var(--spacing-0);
    &.breadcrumb-width{
      .breadcrumb__left{
        width: 80%;
      }
      .breadcrumb__right{
        width: 20%;
      }
    }
  }


/* ------------------------> Filters Dropdown Style <------------------------ */
.CommonFiltersPanel{
    justify-content: flex-start; 
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: initial; 
    gap: var(--spacing-16); 
    .FiltersHolder{
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
    }
    .ResetFiltersTxt{
      height: 48px;
      @media screen and (max-width: 1300px) {
        width: 124px;
      }
     .ResetAllTxt{
       color: var(--font-color-disabled);
       text-decoration-line: underline;
       cursor: not-allowed;
       &.active{
        color: var( --primary-default);
        cursor: pointer;
       }
     }
    }
 }


/* ------------------------> Highcharts Customization Style <------------------------ */

  .highcharts-credits {
    display: none !important;
  }
  
  .highcharts-container{
    text {
      font-family: var(--font-family-roboto-regular);
    }
  }
  .highcharts-navigator {
    stroke-width: 1;
    stroke: #DBE3ED;
  }



 /* ------------------------> Table Customization Style <------------------------ */
 .table-main{
  margin-top: var(--spacing-24);
  .table__wrapper{
    snl-row{
      &:last-child{
        snl-td{
          border-bottom: none;
          overflow: hidden;
        }
      }
    }
  }
 }


.table__wrapper{
  snl-row{
    .sticky_col{
      position: sticky;
      left: 0; 
      background: #fff;
      z-index: 99;
    }
  }
}

.LastRowDropdown{
  .table__wrapper{
    snl-row{
      &:last-child, &:nth-last-child(2), &:nth-last-child(3){
        snl-td{
          ng-select{
            .ng-dropdown-panel{ 
                bottom: auto;
                margin: 0; 
                box-shadow: -6px -4px 30px rgba(30, 33, 46, 0.06); 
            }
          }
        }
      }
    }
  }
}

.boder__right__bold{
    border-right: var(--spacing-2) solid var(--neutral-9);
  }
  .boder__bottom__bold{
    border-bottom: var(--spacing-2) solid var(--neutral-8) !important;
  } 
  .boder__right__light{
    border-right: var(--spacing-1) solid var(--neutral-7);
  }
  .txt__error{
    color: var(--semantic-9) !important;
  }
  .th__icon{
    snl-icon{ 
      height: 100%;
      width: 100%;
    }
  }

  snl-th{
    i{ 
      &.fa{
        background-image: url("../img/sort-regular.svg");
        background-repeat: no-repeat;
        width: 100%;
        height: 100%; 
        &::before{
          display: none;
        }
      } 
    }
    &.active{ 
      i{ 
        &.fa{
          background-image: url("../img/sort-up-solid.svg");
          &.fa-sort-down{
            background-image: url("../img/sort-down-solid.svg"); 
          }
        }
      }  
    }
  }
  .shorting-space-0{
    .th__icon{
      margin: 0 !important;
    }
  } 
  .tooltipcstm{
    .snl-tooltip{
      .tooltip-heading{
        font-family: var(--font-family-roboto-regular);
        border-color: #FF1300 !important; 
      }
    }
  } 
  .th-height-controller{
    .th__wrapper{
      height: auto !important;
    }
  } 
  .shorted__td{
    background: var(--primary-accent) !important; 
    border-width: 0px var(--spacing-1);
    border-style: solid; 
    border-color: var(--primary-default);
    span{
      font-weight: var(--font-weight-700);
    }
  }
  .CstmWidth__114{
    .th__text{
      width: 114px;
    }
  } 
  
  @mixin d-flex-start {
    display: flex;
    align-items: flex-start;
  }
  
  .ShortingTxt{
    @include d-flex-start();
    padding-top: var(--spacing-12);
    .section-title{
      padding: var(--spacing-1) !important;
    } 
  }
  
  .snl-data-bottom{
    @include d-flex-start(); 
    height: var(--spacing-72); 
  }
  
  .firstcolumncheckbx{
    snl-checkbox{
      margin-top: var(--spacing-4);
    }
    .th__wrapper{
      padding-left: var(--spacing-16);
    }
  } 
  .btm-txt{
    padding-left: var(--spacing-16);
    @media only screen and (max-width: 1300px){
      font-size: 14px;
      top: -6px;
    }
  }
  .snl-data-main{
    display: flex;
    flex-direction: column; 
    &.ytd-wdth{
      width: 63px;
    } 
    .DistributionAmount{
      position: absolute;
      min-width: 180px;
      z-index: 9;
    }
    .scrubberDistributionAmount{
      position: absolute;
      min-width: 195px;
      z-index: 9;
    }
    .netPoolDistributionAmount{
      position: absolute;
      min-width: 177px;
      z-index: 9;
    }
  } 
  .snl-data-top{
    height: var(--spacing-48);
    display: flex;
    align-items: center;
  }
  
  .check_cstomz{
    snl-checkbox{
      margin-top: var(--spacing-4);
    }
    .container-c-bx{
      padding-left: var(--spacing-16);
    }
  }
  
  .floating-case-main{
    position: relative;
    .floating-case{ 
        min-width: 115px;
        display: inline-block;
        &.DistributionAmount{
          min-width: 175px;
        }
        &.scrubberDistributionAmount{
          min-width: 195px;
        }
        &.netPoolDistributionAmount{
          min-width: 177px;
        }
        
        &.ColumnPoolPoints{
          min-width: 85px;
          &.pp-wdth{
            min-width: 50px;
            word-break: break-word;
            display: block; 
          }
        } 
    }
  }
  
.PP-width{
  width: 100px;
}

  .thin-scroll-x{
    &::-webkit-scrollbar {
      height: var(--spacing-8);
    }
    &::-webkit-scrollbar-thumb {
      border-top: var(--spacing-2) solid transparent;
      border-bottom: var(--spacing-2) solid transparent;
    }
  }


.table-custom-2{
  .td__wrapper{
    .data-text{
      padding: 12px var(--spacing-16) !important; 
    }
    .td__checkbox{
      width: 56px !important;
      align-items: center;
      padding-top: 0 !important;
    }
  }
  .no-checkbx{
    .td__checkbox{
      display: none;
    }
  }
}  


/* ------------------------> Bottom Sticky Bar Style <------------------------ */
.BottomStickyBar{
    position: sticky;
    left: var(--spacing-0);
    right: var(--spacing-0);
    bottom: var(--spacing-0);
    z-index: 1;
    &.z-index-0{
      z-index: 0;
    }
    snl-action-panel{
      box-shadow: 0px -5px 15px rgba(30, 33, 46, 0.06); 
    }
}



/* ------------------------> Side Navbar Style <------------------------ */ 
.sidebar{ 
  min-width: 212px; 
  position: fixed;
  left: 0; 
}
.leftbar-collapsed{ 
  .sidebar{ 
    min-width: 72px;
  }
  .DataBodyContaner{
    padding-left: 72px !important;
  }
}







/*================================================================================
                                  Common Classes 
==================================================================================*/

/* ------------------------> Flex Behaviors <------------------------ */
    .d-flex{display: flex;}
    .justify-content-start{justify-content: start;}
    .justify-content-between{justify-content: space-between;}
    .align-items-start{align-items: start;}
    .align-items-center{align-items: center;}
    .align-items-end{align-items: end;}
    .flex-column{flex-direction: column;}
    .position_relative{position: relative;}
    .cursor-all-scroll{cursor: all-scroll !important;}
    .half-containers {flex: 1;}
  

/* ------------------------> Spacing Inside & Outside <------------------------ */
    .m-0{margin: var(--spacing-0) !important;}
    .p-0{padding: var(--spacing-0) !important;}
    .pb-0{padding-bottom: var(--spacing-0) !important;}

    .margin-10 {margin: 10px !important;} 
  
    .col-gap-20{column-gap:20px;}
    .col-gap-12{column-gap:var(--spacing-12);}
    .col-gap-14{column-gap:var(--spacing-14);}
    .col-gap-8{column-gap:var(--spacing-8);}
    .row-gap-8{row-gap:var(--spacing-8);}
  


  

  /* ------------------------> Theme Colors <------------------------ */
    .color__primary_default{color: var(--font-color-primary-default);}
    .color__primary{color: var(--font-color-primary);}
    .color__secondary{color: var(--font-color-secondary);}
    .color__neutral{color: var(--font-color-neutral);}
    .color__black{color: var(--font-color-black);}
  


  /* ------------------------> Alignment <------------------------ */
  .text__center{text-align: center;} 
  .text__start{text-align: start;} 
  .text__end{text-align: right;} 
  



  /* ------------------------> Font Weight <------------------------ */
  .fw-700{font-weight: var(--font-weight-700);}
  .fw-400{font-weight: var(--font-weight-400) !important;}
  



 /* ------------------------> Border Radius <------------------------ */ 
  .border__radius4{border-radius: var(--border-radius-4);}
  .border__radius6{border-radius: var(--border-radius-6);}
  .border__radius8{border-radius: var(--border-radius-8);}
  .border__radius999{border-radius: var(--border-radius-999);}
  
  
  
  
  /* ------------------------> Common Reusable Style <------------------------ */
  .theme-container{
    position: relative;
    min-height: 100vh;
    .theme-container-inner{
    padding: var(--spacing-0) var(--spacing-24); 
    min-height: 100vh;
    }
  }
  
  .latest__v{
    &.theme-container{
      .theme-container-inner{
        min-height: calc(100vh - 188px);
      }
    }
  }
 

  .truncate-txt{
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    &.single-line{ 
      -webkit-line-clamp: 1;
    }
  }

  #toast-container{
    .toast-message{
      color: #27364B;
      font-family: var(--font-family-roboto-regular);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;  
      letter-spacing: 0.2px;
      padding-left: 16px;
    }
    .toast-success { 
      background: #E8FCF1;
      box-shadow: 0px 1px 2px rgba(15, 26, 42, 0.25);
      border-radius: 4px;
      padding: 8px 16px; 
      padding-left: 24px;
    &::before{
        position: absolute;
        content: ""; 
        background-image: url("../img/info-toast.svg") !important;
        width: 16px;
        height: 16px;
        border-radius: 50%; 
        left: 16px;
        top: 12px;
      }
    }
}
  

  .NoRecordsFound{
    min-height: 80px;
  }

  .Skelton-pp{
    background: #e3e3e3;
    width: 100%;
    display: block;
    height: 10px; 
  }

  .Skelton-fh{
    background: #C1C4C8;
    width: 100%;
    display: block;
    height: 10px; 
  }
 
 
  .DataNotAvailable{  
    color: var(--font-color-neutral);
    padding: 20px;
  }
  .ButtonHolder404{  
    .fa{
        font-size: var(--spacing-12);
        color: var(--neutral-12) !important;
    }
  }
  .StickyThead{
    position: sticky;
    top: 0;
    z-index: 999;  
    &.StickyDataRow{
      top: 120px;
      background-color: var(--neutral-12);
    }
  }

  @-moz-document url-prefix() {
    .stickymz-browser{
      display: table-header-group !important;
    }
  }
    
  button{
    &:disabled{
      cursor: not-allowed !important;
    } 
  }
  .border-body-tbl{
    border: var(--spacing-1) solid var(--neutral-9);
  }

  .date-picker{
    background-color: var(--neutral-12);
  }
  .model__customize{
    .modal__wrapper-small{
      width: 348px !important;
      border-radius: 0 !important;
      .modal__header-icon{
        display: none !important;
      }
      .modal__footer{
        button{
          padding: 4px 35px !important;
        }
      }
    }
  }

.three-dot{
  right: 0;
  position: absolute;
  transform: rotate(90deg);
  top: -12px;
  width: var(--spacing-24);
  height: var(--spacing-24);
  cursor: pointer;
  i{
    color: var(--font-color-secondary) !important;
  }
}

.search-common{
  width: 225px;
  height: var(--spacing-48);
  background: #FFFFFF;
  border: var(--spacing-1) solid #CBD4E1;
  border-radius: var(--spacing-4);     
  padding: 12px 48px 12px 16px;
  font-weight: 400;
  font-size: var(--spacing-16);
  line-height: var(--spacing-24);
  outline: none;
  color: var(--font-color-primary);
  background-image: url("../img/search-icon.svg");
  background-repeat: no-repeat;
  background-position: right var(--spacing-16) center;
  &::placeholder{
    color: var(--font-color-neutral);
  }
}

.CommonPillsMain{
  display: flex;
  cursor: pointer;
  .CommonPills{
    visibility: hidden;  
    padding: var(--spacing-0) var(--spacing-16);
    background: var(--neutral-8);
    border-radius: 6px;
    margin-left: var(--spacing-12); 
  }
  &:hover{
    .CommonPills {
      visibility: visible; 
    }
  }
}

ng-select{  
  &.ng-select-opened{
    .ng-select-container{
      .ng-value-container{
        background-image: url("../img/drop-up.svg") !important;
        border: var(--border-width-1) solid var(--primary-default); 
      }
    }
  }
  &.btm-border-select{
    .ng-select-container{
      .ng-value-container{ 
        border-radius: 0;
        border: none; 
        border-bottom: var(--border-width-1) solid var(--neutral-8);
      }
    }
  } 
  .ng-select-container{
    .ng-value-container{
        background: var(--neutral-12);
        border: var(--border-width-1) solid var(--neutral-8);
        border-radius: 6px;
        padding: var(--spacing-12) var(--spacing-16);
        width: -moz-fit-content;
        width: fit-content;
        --icon-text-color: var(--neutral-6); 
        padding-right: 40px;
        cursor: pointer;
        background-image: url("../img/drop-down.svg") !important;
        background-repeat: no-repeat !important;
        background-position: center right 16px !important;
        background-size: 16px !important;
        position: relative;
        .ng-placeholder {
          position: static !important;
          font-weight: bold;
          color: #27364B;
        }
        span{
          font-family: var(--font-family-roboto-bold);
          font-size: var(--font-size-16);
          line-height: var(--line-height-24);
          letter-spacing: var(--letter-spacing-2);
          font-style: var(--font-style-normal);
        } 
        input{
          cursor: pointer;
        }
    }
  }

  .ng-dropdown-panel{
    background: var(--neutral-12);
    box-shadow: var(--elevation-e3);
    border-radius: var(--border-radius-4);
    padding: var(--spacing-8) 0px;
    margin-top: var(--spacing-8);
    min-width: 360px;
    display: inline-block;
    left: 0;
    .ng-dropdown-header{
      padding: 0px var(--spacing-8) var(--spacing-12) var(--spacing-8);
      .content__search{
        display: flex;
        border-radius: var(--border-radius-4);
        background-color: var(--neutral-12);
        padding: var(--spacing-12) var(--spacing-16);
        border: var(--border-width-1) solid var(--neutral-8);
        height: 48px;
        position: relative;
        &:focus-within {
          border: var(--border-width-1) solid var(--primary-default);
        }
        &:hover{
          border: var(--border-width-1) solid var(--primary-default);
          --icon-text-color: var(--primary-hover);
          input{
            &::placeholder{
              color: var(--font-color-primary);
            }
          }
        }
        input{
        padding: 0;
        border: none;
        width: 100%;
        outline: none;
        background-color: var(--neutral-12);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--font-color-primary);
          &::placeholder{
            color: var(--font-color-neutral);
            letter-spacing: var(--letter-spacing-2);
          }
        }
        .content__icon--wrapper{
          cursor: pointer;
        }
      }
    }
    .ng-dropdown-panel-items {
      .ng-option{
        padding: var(--spacing-12) var(--spacing-16);
        color: var(--font-color-primary);
        border-bottom: var(--border-width-1) solid var(--neutral-10);
        background: var(--neutral-12);
        --icon-text-color: var(--neutral-6);
        cursor: pointer;
        &:hover{
          background: var(--neutral-11);
          --icon-text-color: var(--primary-hover);
        }
      }
    }
    .ng-dropdown-footer{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      column-gap:var(--spacing-16);
      padding: var(--spacing-16) var(--spacing-8) 0;
      snl-button{
        width: 100%;
        button{
          width: 100%;
        }
      }
    }
    
    .scroll-host::-webkit-scrollbar {
      width: var(--spacing-8);
    }
    
    .scroll-host::-webkit-scrollbar-track {
      background-color: var(--scroll-background);
      border-width: 0px 0.5px;
      border-style: solid;
      border-color: var(--scroll-border);
    }
    
    .scroll-host::-webkit-scrollbar-thumb {
      border-right: 2px solid transparent;
      border-left: 2px solid transparent;
      background-clip: padding-box;
      border-radius: var(--border-radius-8);
      background-color: var(--scroll-thumb-background);
    
      &:hover {
        background-color: var(--scroll-thumb-hover);
      }
    }
  }
   
}

.dropdown-col{
  ng-select{ 
    .ng-select-container{
      .ng-value-container{ 
        &::before{
          color: #27364B;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      } 
    }
  }
  &._TextFltr{
    ng-select{ 
      .ng-select-container{
        .ng-value-container{ 
          overflow: inherit !important;
          border-radius: 4px !important;
          padding-left: 12px;
          background: var(--neutral-n-12, #FFF);
          &::before{
            z-index: 1;
            position: absolute;
            left: 12px;
            top: -8px;
            font-family: var(--font-family-roboto-regular);
            background-color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.2px;
          }
        } 
      }
    }
  }
  &._TextShip{
    ng-select{ 
      .ng-select-container{
        .ng-value-container{
          &::before{
            content: "Ship";
          }
        } 
      }
    } 
  }

  &._TextShipOwner{
    ng-select{ 
      .ng-select-container{
        .ng-value-container{
          &::before{
            content: "Ship Owner";
          }
        } 
      }
    } 
  }

  &._TextShipOwnerFin{
    ng-select{ 
      .ng-select-container{
        .ng-value-container{
          &::before{
            content: "Ship owner";
          }
        } 
      }
    } 
  }

  &._TextFyear{
    ng-select{ 
      .ng-select-container{
        .ng-value-container{
          &::before{
            content: "Financial year";
          }
        } 
      }
    } 
  }

  &._TextShipOwnerSTJS{
    ng-select{ 
      .ng-select-container{
        .ng-value-container{
          &::before{
            content: "Ship Owner";
          }
        } 
      }
    } 
  }

  &._TextSubPool{
    ng-select{ 
      .ng-select-container{
        .ng-value-container{
          height: 48px;
          &::before{
            content: "Sub Pool";
          }
        } 
      }
    } 
  }
  &._TextClass{
    ng-select{ 
      .ng-select-container{
        .ng-value-container{
          &::before{
            content: "Class";
          }
        } 
      }
    } 
  }
    &._TextStatus{
    ng-select{ 
      .ng-select-container{
        .ng-value-container{
          &::before{
            content: "Status";
          }
        } 
      }
    } 
  }
 

}


/* ------------------------> Dropdown Card Customize <------------------------ */
.buttons-grp{
  column-gap: 20px;
  snl-button{
    button{
      position: relative; 
    } 
    &:hover{
      .custom-dropdwn{ 
        display: flex !important;
      }
    }
  }
  &.DisabledBtn{
    cursor: not-allowed;
    snl-button{
      pointer-events: none;
    }
  }
}
.custom-dropdwn{ 
  background: var(--neutral-12);
  position: absolute;
  width: 250px;
  filter: drop-shadow(0px 10px 15px rgba(15, 26, 42, 0.1));
  border-radius: 10px;
  top: var(--spacing-32);
  right: 0;
  display: none; 
  z-index: 1;
  &.top-direc{
    top: inherit;
    bottom: 32px;
    filter: drop-shadow(0px 0px 15px rgba(15, 26, 42, 0.1));
  }
  &.left-direc{
    right: inherit;
    left: 0;
  }
  a, span{
    padding: var(--spacing-12) var(--spacing-16);
    text-decoration: none;
    color: var(--font-color-primary);
    border-bottom: var(--spacing-1) solid var(--neutral-10);
    width: 100%;
    text-align: left;
    display: flex;
    cursor: pointer;
    &:hover{
      background: var(--neutral-11);
    }
  }
}

.action{
  position: relative; 
  &:hover{
    .custom-dropdwn {
      display: block; 
      top: 12px;
      filter: drop-shadow(0px 3px 12px rgba(15, 26, 42, 0.25));
      border-radius: 2px;
    }
  }

}
.cursor-pointer{
  cursor: pointer;
}

.tooltip-common{ 
  padding: 18px 20px;  
  background: #FFFFFF;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  left: 0;
  top: 20px !important;
  width: 333px;
   div{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    p{  
      padding: 0;
      margin: 0;
      font-weight: 400;
      font-size: var(--font-size-14);
      line-height: 20px; 
      letter-spacing: 0.2px; 
      color: #000000;
      font-family: var(--font-family-roboto-regular);
    }
   }

}

 